/* eslint-disable max-lines-per-function */
import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { trackScreenViewEvent } from '@avita-co-jp/frontend-utils';
import { addMessageLog } from '../../../firebase';
import { InstanceType, TMessage } from '../../../service/type';
import { appState, chatbotIdentityState, messageState } from '../../../states';

const MESSAGE_SHOW_INTERVAL = 1000;

// NOTE: この関数は、ChatContextで使用するための関数です。
export const useMessageUtil = () => {
  const app = useRecoilValue(appState);
  const setMessages = useSetRecoilState(messageState);
  const chatbotIdentity = useRecoilValue(chatbotIdentityState);
  const { identity } = chatbotIdentity;

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (msec: number) => new Promise((resolve) => setTimeout(resolve, msec));

  const messageAction = useCallback(
    async (message: TMessage, projectId: number, channelId: string) => {
      switch (message.instance_type) {
        case InstanceType.text_message: {
          await addMessageLog(String(projectId), channelId, message.params.display_text, app.chatKey);
          setMessages((prev) =>
            prev.concat({
              ...message,
              user_created: 'avatar',
              created_at: new Date().toISOString(),
              params: { ...message.params, display_text: message.params.display_text },
            }),
          );
          trackScreenViewEvent('show_message', {
            event_label: channelId,
            value: projectId,
            description: message.params.display_text,
          });
          await sleep(MESSAGE_SHOW_INTERVAL);
          break;
        }
        case InstanceType.end_of_scenario: {
          setMessages((prev) => prev.concat(message));
          trackScreenViewEvent('sent_email', {
            event_label: channelId,
            value: projectId,
          });
          break;
        }
        case InstanceType.user_input: {
          setMessages((prev) => prev.concat(message));
          break;
        }
        case InstanceType.bot: {
          // NOTE: botのメッセージはfireStoreとbigQueryに依存しないように、一旦コメントアウト
          // await addMessageLog(String(projectId), channelId, message.params.message, app.chatKey);
          setMessages((prev) =>
            prev.concat({
              ...message,
              created_at: new Date().toISOString(),
            }),
          );
          // trackScreenViewEvent('show_message', {
          //   event_label: channelId,
          //   value: projectId,
          //   description: message.params.message,
          // });
          break;
        }
        case InstanceType.avatar_intents_answer: {
          await addMessageLog(String(projectId), channelId, message.params.message, app.chatKey);
          setMessages((prev) =>
            prev.concat({
              ...message,
              created_at: new Date().toISOString(),
            }),
          );
          trackScreenViewEvent('show_message', {
            event_label: channelId,
            value: projectId,
            description: message.params.message,
          });
          break;
        }
        case InstanceType.avatar_chats_answer: {
          await addMessageLog(String(projectId), channelId, message.params.message, app.chatKey);
          setMessages((prev) =>
            prev.concat({
              ...message,
              created_at: new Date().toISOString(),
            }),
          );
          trackScreenViewEvent('show_message', {
            event_label: channelId,
            value: projectId,
            description: message.params.message,
          });
          break;
        }
        default:
          setMessages((prev) => prev.concat(message));
          break;
      }
    },
    [app.chatKey, setMessages],
  );

  const addMessage = useCallback(
    async (messages: TMessage[], projectId = identity.projectId, channelId: string = chatbotIdentity.channelId) => {
      // eslint-disable-next-line no-restricted-syntax
      for await (const message of messages) {
        await messageAction(message, projectId, channelId);
      }
    },
    [chatbotIdentity.channelId, identity.projectId, messageAction],
  );

  const addUserMessage = useCallback(
    async (message: string) => {
      const userMessage: TMessage[] = [
        {
          user_created: identity.firebaseUuid,
          created_at: new Date().toISOString(),
          instance_type: InstanceType.text_message,
          params: {
            display_text: message,
          },
        },
      ];
      const projectId = String(chatbotIdentity.identity.projectId);
      addMessageLog(projectId, chatbotIdentity.channelId, message, app.chatKey)
        .then()
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
      setMessages((prev) => prev.concat(userMessage));
      await sleep(MESSAGE_SHOW_INTERVAL);
    },
    [app.chatKey, chatbotIdentity, identity.firebaseUuid, setMessages],
  );

  return {
    addMessage,
    addUserMessage,
  };
};
