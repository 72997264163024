/* eslint-disable max-lines-per-function */
import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { trackUserId, trackScreenViewEvent } from '@avita-co-jp/frontend-utils';
import { createChannel } from '../../../../firebase';
import { signInByCustomToken } from '../../../../firebase/auth';
import { useServices } from '../../../../service';
import { appState, chatbotIdentityState } from '../../../../states';
import { useMessageUtil } from '../../utils/message.utils';
import { TMessage } from '../../../../service/type';
import {
  generateInitialMessage1,
  generateInitialMessage2,
  generateInitialMessage3,
  generateInitialMessage4,
} from '../../../../consts/setting';
import { initialMassageSendingState } from '../../../../states/loading/intentsLoadingState';

export const useStartScenario = () => {
  const app = useRecoilValue(appState);
  const setIdentity = useSetRecoilState(chatbotIdentityState);
  const { signInService } = useServices();
  const setIsChatsLoading = useSetRecoilState(initialMassageSendingState);

  const { addMessage } = useMessageUtil();
  const startScenario = useCallback(async () => {
    try {
      setIsChatsLoading(true);
      await addMessage(generateInitialMessage1() as TMessage[]);
      await new Promise<void>((resolve) => {
        setTimeout(() => resolve(), 1000);
      });
      await addMessage(generateInitialMessage2() as TMessage[]);
      await new Promise<void>((resolve) => {
        setTimeout(() => resolve(), 1000);
      });
      await addMessage(generateInitialMessage3() as TMessage[]);
      await new Promise<void>((resolve) => {
        setTimeout(() => resolve(), 1000);
      });
      await addMessage(generateInitialMessage4() as TMessage[]);

      const authResponse = await signInService.signIn({ apiKey: app.apiKey });
      await signInByCustomToken(authResponse.firebaseCustomToken);

      const channelId = await createChannel(
        String(authResponse.identity.projectId),
        authResponse.identity.firebaseUuid,
        app.scenarioId,
        app.chatKey,
      );
      trackUserId(authResponse.identity.firebaseUuid);

      trackScreenViewEvent('start_chat');
      setIdentity({ identity: authResponse.identity, channelId });
      // NOTE: INITIAL_MESSAGESはfireBase認証の前に表示するため、コメントアウト
      // await addMessage(INITIAL_MESSAGES as TMessage[], authResponse.identity.projectId, channelId);
      setIsChatsLoading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [addMessage, app.apiKey, app.chatKey, app.scenarioId, setIdentity, signInService, setIsChatsLoading]);
  return { startScenario };
};
