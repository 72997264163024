import { Fragment } from 'react';
import Script from 'next/script';

export const GoogleAnalyticsAsyncScript = ({ ids }: { ids?: string[] }): JSX.Element | null => {
  if (!ids || ids.length === 0) {
    return null;
  }

  return (
    <>
      {ids.map((id) => (
        <Fragment key={id}>
          <Script src={`https://www.googletagmanager.com/gtag/js?id=${id}`} strategy="afterInteractive" />
          <Script id={`google-analytics-${id}`} strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${id}');
            `}
          </Script>
        </Fragment>
      ))}
    </>
  );
};
