export enum InstanceType {
  avatar_utterance = 'avatar_utterance',
  user_input = 'user_input',
  text_message = 'text_message',
  bot = 'bot',
  avatar_motion = 'avatar_motion',
  avatar_intents_answer = 'avatar_intents_answer',
  avatar_chats_answer = 'avatar_chats_answer',
  avatar_conservative_answer = 'avatar_conservative_answer',
  initial_message = 'initial_message',
  google_form_input = 'google_form_input',
  google_form_thanks = 'google_form_thanks',
  google_form_info = 'google_form_info',

  // ====== ↓今のところ未使用↓ ======
  avatar_motion_video = 'avatar_motion_video',
  open_image = 'open_image',
  close_image = 'close_image',
  open_video = 'open_video',
  close_video = 'close_video',
  user_choices = 'user_choices',
  end_of_scenario = 'end_of_scenario',
}

export type TFacialExpression = 'SMILE';

export type TGesture = 'BOW';

export interface IMessageOpenAIAnswer extends IMessage {
  instance_type: InstanceType.bot;
  params: {
    message: string;
    intent_ids: number[];
    intent_summaries: string[];
  };
}
export interface IMessageIntentsAnswer extends IMessage {
  instance_type: InstanceType.avatar_intents_answer;
  params: {
    message: string;
    intent_ids: number[];
    intent_summaries: string[];
  };
}
export interface IMessageChatsAnswer extends IMessage {
  instance_type: InstanceType.avatar_chats_answer;
  params: {
    message: string;
  };
}
export interface IMessageInitialMessage extends IMessage {
  instance_type: InstanceType.initial_message;
  params: {
    message: string;
  };
}
export interface IMessageGoogleFormInputMessage extends IMessage {
  instance_type: InstanceType.google_form_input;
  params: {
    message: string;
  };
}
export interface IMessageGoogleFormThanksMessage extends IMessage {
  instance_type: InstanceType.google_form_thanks;
  params: {
    message: string;
  };
}

export interface IMessageGoogleFormInfoMessage extends IMessage {
  instance_type: InstanceType.google_form_info;
  params: {
    message: string;
  };
}

export interface IMessageTextMessage extends IMessage {
  instance_type: InstanceType.text_message;
  params: {
    display_text: string;
  };
}

export interface IMessageEndOfScenario extends IMessage {
  instance_type: InstanceType.end_of_scenario;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;
}

export interface IMessageAvatarUtteranceParams {
  display_text: string;
  facial_expression: TFacialExpression;
  gesture?: string;
}

export interface IMessageAvatarMotion extends IMessage {
  instance_type: InstanceType.avatar_motion;
  params: IMessageAvatarMotionParams;
}

export interface IMessageAvatarMotionParams {
  // FIXME: 意図せずgestureが入ってしまっている可能性が高いので、修正する
  gesture: string;
  motion_name: string;
  expression_name: string;
}

export interface IMessageUserInput extends IMessage {
  instance_type: InstanceType.user_input;
  params: IMessageUserInputParams;
}

export interface IMessageUserInputParams {
  input_type: string;
  save_name: string;
}

export interface IMessage {
  instance_type: InstanceType;
  user_created: string;
  created_at: string;
  params: TMessageParams;
}

export type TMessageParams =
  | IMessageAvatarMotionParams
  | IMessageUserInputParams
  | IMessageTextMessage['params']
  | IMessageEndOfScenario['params']
  | IMessageOpenAIAnswer['params']
  | IMessageIntentsAnswer['params']
  | IMessageChatsAnswer['params']
  | IMessageInitialMessage['params']
  | IMessageGoogleFormInputMessage['params']
  | IMessageGoogleFormThanksMessage['params']
  | IMessageGoogleFormInfoMessage['params'];

export type TMessage =
  | IMessageAvatarMotion
  | IMessageUserInput
  | IMessageEndOfScenario
  | IMessageTextMessage
  | IMessageOpenAIAnswer
  | IMessageIntentsAnswer
  | IMessageChatsAnswer
  | IMessageInitialMessage
  | IMessageGoogleFormInputMessage
  | IMessageGoogleFormThanksMessage
  | IMessageGoogleFormInfoMessage;

export type TResponse = {
  scenario_data: TMessage[];
};

// ======================== ↓今のところ未使用↓ ========================

export interface IMessageAvatarUtterance extends IMessage {
  instance_type: InstanceType.avatar_utterance;
  params: IMessageAvatarUtteranceParams;
}

export interface IMessageAvatarMotionVideo extends IMessage {
  instance_type: InstanceType.avatar_motion_video;
  params: IMessageAvatarMotionVideoParams;
}

export interface IMessageAvatarMotionVideoParams {
  motion_url: string;
}

export interface IMessageOpenImage extends IMessage {
  instance_type: InstanceType.open_image;
  params: IMessageOpenImageParams;
}

export interface IMessageOpenImageParams {
  image_url: string;
}

export interface IMessageCloseImage extends IMessage {
  instance_type: InstanceType.close_image;
  params: IMessageCloseImageParams;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMessageCloseImageParams {}

export interface IMessageOpenVideo extends IMessage {
  instance_type: InstanceType.open_video;
  params: IMessageOpenVideoParams;
}

export interface IMessageOpenVideoParams {
  video_url: string;
  auto_close_if_play_end: boolean;
}

export interface IMessageCloseVideo extends IMessage {
  instance_type: InstanceType.close_video;
  params: IMessageCloseVideoParams;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMessageCloseVideoParams {}

export interface IMessageUserChoices extends IMessage {
  instance_type: InstanceType.user_choices;
  params: IMessageUserChoicesParams;
}

export interface IMessageUserChoicesParams {
  choices: string[];
  save_name: string;
}
