import { TResponse, TMessage } from '../type';

type TParam = {
  role: string;
  content: string;
};

// eslint-disable-next-line no-promise-executor-return
const sleep = (msec: number) => new Promise((resolve) => setTimeout(resolve, msec));

export async function fetchIntents(query: string, params: TParam[], retryCount = 3): Promise<TMessage> {
  const endpoint = `${process.env.NEXT_PUBLIC_FEATURE_SIKMI_ENDPOINT || 'http://localhost:18000'}/intents`;
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query, messages: params }),
    credentials: 'include',
  });

  if (!response.ok) {
    if (retryCount <= 0) {
      throw new Error('回答の取得に失敗しました。');
    }
    await sleep(3000);
    const responseJson = await fetchIntents(query, params, retryCount - 1);
    return responseJson;
  }

  const data = (await response.json()) as TResponse;
  return data.scenario_data[0];
}
