// 本ファイルは、いずれDBに移行する予定です。

import { InstanceType } from '../service/type';

// システムプロンプト。前提条件、キャラクター設定等。
export const SYSTEM_MESSAGE_CONTENT = `You are a company employee in Tokyo. You are a woman. If a personal question comes to you, imagine and answer it. If the question is personal, please answer with shame.`;

// 最初に表示するメッセージ
export const INITIAL_MESSAGES = [
  {
    instance_type: InstanceType.initial_message,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: {
      message: 'お問い合わせいただき、ありがとうございます。\nAIアバターの私が質問に回答させていただきます。',
    },
  },
];

// エラーが起きた後３回はリトライするけどそれでもダメな時に表示するメッセージ
export const ERROR_MESSAGE = [
  {
    instance_type: InstanceType.bot,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: {
      message: '申し訳ございません、現在ご利用いただけません。しばらく経ってから再度お試しください。',
    },
  },
  {
    instance_type: InstanceType.user_input,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: {
      input_type: 'text',
      save_name: '',
    },
  },
];

export const ERROR_MAX_CONVERSATION_LENGTH = [
  {
    instance_type: InstanceType.bot,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: { message: '入力が長すぎるので、もう少し短くしていただけませんか？' },
  },
];

// OPEN_AIを使った会話で、ユーザーが一回の入力出来る文字数の最大値
export const MAX_MESSAGE_LENGTH = 500;

// 会話の最大長。越えると最初の会話をSliceしてAPIを投げる。
export const MAX_CONVERSATION_LENGTH = 20;

// openaiを使うか、azureを使うか
export const AI_SERVICE: 'openai' | 'azure' = 'azure';

// CANNOT_ANSWER時に表示するメッセージ
export const CANNOT_ANSWER_MESSAGE =
  '申し訳ありません。アバターオペレーターですとお問い合わせ内容にお答えできる可能性がございます。「保険市場」では、アバターを用いたオンライン保険相談を提供しています。詳細はこちらのページをご覧ください。 https://www.hokende.com/lp/soudan/avatar_online_soudan';

// 定型文は定義された時間がcreated_atに入るため、関数で定義している
export const generatePersonalDataInputMessage = () => [
  {
    instance_type: InstanceType.google_form_input,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: {
      message: '',
    },
  },
];

export const generatePersonalDataInfoMessage = () => [
  {
    instance_type: InstanceType.google_form_info,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: {
      message:
        '個人情報の入力は、上記のお問い合わせフォームのみでお願いします。\nアバターチャットとの会話中に個人情報を入力しないようにご注意ください。',
    },
  },
];

export const generateThanksMessage = () => [
  {
    instance_type: InstanceType.google_form_thanks,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: {
      message: 'お問合せいただきありがとうございます。\nお問い合わせの内容を確認させていただき、追って担当者よりご連絡させていただきますので、今しばらくお待ちください。',
    },
  },
  {
    instance_type: InstanceType.user_input,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: {
      input_type: 'text',
      save_name: '',
    },
  },
];

export const generateInitialMessage1 = () => [
  {
    instance_type: InstanceType.initial_message,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: {
      message: 'お問い合わせいただき、ありがとうございます。\nAIアバターの私が質問に回答させていただきます。',
    },
  },
];
export const generateInitialMessage2 = () => [
  {
    instance_type: InstanceType.initial_message,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: {
      message:
        '私はAI（人工知能）なので、有人によるチャットではなく、内容の正確性と最新性等は保証できません。もしも私が質問の意図を理解できない時は、表現を変えてもう一度質問をしてみてください。\n個人情報は送信しないようにご注意ください。',
    },
  },
];
export const generateInitialMessage3 = () => [
  {
    instance_type: InstanceType.initial_message,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: {
      message:
        'チャット内容の、第三者への転送、転用、二次利用などはご遠慮ください。\nお客さまの契約状況を特定したご質問には回答できません。',
    },
  },
];
export const generateInitialMessage4 = () => [
  {
    instance_type: InstanceType.initial_message,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: {
      message: '保険市場に関して何かご質問はございますか？',
    },
  },
  {
    instance_type: InstanceType.user_input,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: {
      input_type: 'text',
      save_name: 'first_message',
    },
  },
];
