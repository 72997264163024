import { atom } from "recoil";

export const intentsLoadingState = atom({
  key: 'intentsLoadingState',
  default: false,
});

export const chatsLoadingState = atom({
  key: 'chatsLoadingState',
  default: false,
});

export const formLoadingState = atom({
  key: 'formLoadingState',
  default: false,
});

export const initialMassageSendingState = atom({
  key: 'massageSendingState',
  default: false,
});