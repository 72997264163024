import { TResponse, TMessage } from '../type';

type TParam = {
  role: string;
  content: string;
};

// eslint-disable-next-line no-promise-executor-return
const sleep = (msec: number) => new Promise((resolve) => setTimeout(resolve, msec));

export async function fetchChats(
  query: string,
  intent_ids: number[],
  params: TParam[],
  retryCount = 3,
): Promise<TMessage> {
  const endpoint = `${process.env.NEXT_PUBLIC_FEATURE_SIKMI_ENDPOINT || 'http://localhost:18000'}/chats`;

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query,
      intent_ids,
      client_key: `${process.env.NEXT_PUBLIC_FEATURE_CLIENT_KEY || ''}`,
      messages: params,
    }),
    credentials: 'include',
  });

  if (!response.ok) {
    if (response.status === 429) {
      throw new Error('リクエスト制限に達しました。しばらく経過してから再度お試しください。');
    }
    if (retryCount <= 0) {
      throw new Error('回答の取得に失敗しました。');
    }
    await sleep(3000);
    const responseJson = await fetchChats(query, intent_ids, params, retryCount - 1);
    return responseJson;
  }

  const data = (await response.json()) as TResponse;
  return data.scenario_data[0];
}
