import { atom, atomFamily } from 'recoil';
import { TMessage } from '../../service/type';

export const messageState = atom({
  key: 'messageState',
  default: [] as TMessage[],
});

export const formSubmissionState = atomFamily({
  key: 'formSubmissionState',
  default: false,
});