/* eslint-disable max-lines-per-function */
import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useServices } from '../../../../service';
import { chatsLoadingState, intentsLoadingState, messageState } from '../../../../states';
import { useMessageUtil } from '../../utils/message.utils';
import { IMessageChatsAnswer, IMessageIntentsAnswer, InstanceType, TMessage } from '../../../../service/type';
import {
  CANNOT_ANSWER_MESSAGE,
  ERROR_MAX_CONVERSATION_LENGTH,
  ERROR_MESSAGE,
  MAX_CONVERSATION_LENGTH,
  MAX_MESSAGE_LENGTH,
  generatePersonalDataInfoMessage,
  generatePersonalDataInputMessage,
} from '../../../../consts/setting';
import { formLoadingState } from '../../../../states/loading/intentsLoadingState';

export const useOpenaiChat = () => {
  const messages = useRecoilValue(messageState);
  const { sikmiService } = useServices();
  const setIsIntentsLoading = useSetRecoilState(intentsLoadingState);
  const setIsChatsLoading = useSetRecoilState(chatsLoadingState);
  const setIsFormLoading = useSetRecoilState(formLoadingState);

  const { addMessage, addUserMessage } = useMessageUtil();
  const answerQuestion = useCallback(
    async (params: { userMessage: string }) => {
      const { userMessage } = params;
      await addUserMessage(userMessage);

      if (userMessage.length > MAX_MESSAGE_LENGTH) {
        await addMessage(ERROR_MAX_CONVERSATION_LENGTH as TMessage[]);
      } else {
        const requestParams = messages
          .filter(
            (message) =>
              message.instance_type === InstanceType.text_message ||
              message.instance_type === InstanceType.avatar_chats_answer,
          )
          .map((message) => {
            if (message.instance_type === InstanceType.text_message) {
              return { role: 'user', content: message.params.display_text };
            }
            if (message.instance_type === InstanceType.avatar_chats_answer) {
              return { role: 'assistant', content: message.params.message };
            }
            return { role: 'user', content: '' };
          });

        // NOTE: ７つ以上の会話履歴はリクエストしない
        // NOTE: リクエストする履歴の制限数は奇数にすること（７つなら-6、5つなら-4）
        const limitedRequestParams = requestParams.slice(-6);
        limitedRequestParams.push({ role: 'user', content: userMessage });

        if (limitedRequestParams.length > MAX_CONVERSATION_LENGTH)
          limitedRequestParams.slice(limitedRequestParams.length - 20, limitedRequestParams.length);
        try {
          setIsIntentsLoading(true);
          setIsChatsLoading(false);
          const intentsResponse = (await sikmiService.fetchHybridIntents(
            userMessage,
            limitedRequestParams,
          )) as IMessageIntentsAnswer;
          const intentsMessage = { ...intentsResponse, user_created: 'avatar' };

          const intentIds = intentsMessage.params.intent_ids;
          const chatsResponse = (await sikmiService.fetchChats(
            userMessage,
            intentIds,
            limitedRequestParams,
          )) as IMessageChatsAnswer;

          // CANNOT_ANSWERが含まれている場合は、CANNOT_ANSWER_MESSAGEを表示する
          if (chatsResponse.params.message.includes('CANNOT_ANSWER')) {
            chatsResponse.params.message = CANNOT_ANSWER_MESSAGE;
          }
          const chatsMessage = { ...chatsResponse, user_created: 'avatar' };
          setIsIntentsLoading(false);
          setIsChatsLoading(true);
          await addMessage([chatsMessage]);
          if (chatsResponse.params.message === CANNOT_ANSWER_MESSAGE) {
            setIsFormLoading(true);
            await new Promise<void>((resolve) => {
              setTimeout(() => resolve(), 1200);
            });
            await addMessage(generatePersonalDataInputMessage() as TMessage[]);
            await new Promise<void>((resolve) => {
              setTimeout(() => resolve(), 1200);
            });
            await addMessage(generatePersonalDataInfoMessage() as TMessage[]);
            setIsFormLoading(false);
          }
        } catch (e) {
          await addMessage(ERROR_MESSAGE as TMessage[]);
        } finally {
          setIsIntentsLoading(false);
          setIsChatsLoading(true);
        }
      }
    },
    [addMessage, addUserMessage, messages, sikmiService, setIsIntentsLoading, setIsChatsLoading, setIsFormLoading],
  );

  return { answerQuestion };
};
