import '../styles/globals.scss';
import { AppProps } from 'next/app';
import { RecoilRoot } from 'recoil';
import { ChatProvider } from '../contexts/ChatContext';
import { GoogleAnalyticsAsyncScript } from '../components/atoms/GoogleAnalytics';
import { GoogleTagManagerScript } from '../components/atoms/GoogleTagManagerScript';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      {/* アドクリ用のGTM設置。環境変数に置き換える */}
      <GoogleTagManagerScript gtmId="GTM-KNP4GGS4" />
      {typeof window !== undefined && (
        <RecoilRoot>
          <ChatProvider>
            <Component {...pageProps} />
          </ChatProvider>
        </RecoilRoot>
      )}
      {/* Needs render from server */}
      <GoogleAnalyticsAsyncScript ids={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MULTIPLE_ID?.split(',')} />
    </>
  );
}

export default MyApp;
