import { send } from './email';
import { signIn } from './signIn';
import { fetchQuestionAnswer } from './openai';
import { fetchIntents, fetchChats, fetchConservativeChats, fetchHybridIntents } from './sikmi';

export const useServices = () => {
  const openaiService = { fetchQuestionAnswer };
  const emailService = { send };
  const signInService = { signIn };
  const sikmiService = { fetchIntents, fetchChats, fetchConservativeChats, fetchHybridIntents };

  return {
    emailService,
    signInService,
    openaiService,
    sikmiService,
  };
};
